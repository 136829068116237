/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const ChevronLeft: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
            <mask id='mask0_80_4060' width={24} height={24} x={0} y={0} maskUnits='userSpaceOnUse' style={{
      maskType: "alpha"
    }}>
                <path fill='currentColor' d='M0 0h24v24H0V0Z' />
            </mask>
            <g mask='url(#a)'>
                <path fill='currentColor' d='M13.7 6.7a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L9.8 12l3.9 3.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275l-4.6-4.6a.883.883 0 0 1-.213-.325A1.115 1.115 0 0 1 7.425 12c0-.133.02-.258.062-.375A.883.883 0 0 1 7.7 11.3l4.6-4.6a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275Z' />
            </g>
        </svg>;
};

export default ChevronLeft;